<template>
  <div style="padding-bottom: 200px">
    <RocketLoad
      :full="true"
      v-if="isLoading"
    />

    <template v-else>
      <div class="grid align-items-center justify-content-between">
        <div class="flex pb-3">
          <h1><template>Invoice Detail</template></h1>
        </div>
        <div class="flex pb-3 justify-content-end align-items-center"></div>
      </div>
      <Divider />
      <form
        @submit.prevent="submitHandler"
        v-form-enter
      >
        <div class="p-fluid formgrid grid">
          <div class="field col-12 sm:col-6">
            <label class="label">Invoice Description</label>
            <div class="p-inputgroup">
              <InputText
                placeholder="Invoice Description"
                v-model="name"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label class="label">Reference</label>
            <div class="p-inputgroup">
              <InputText
                mode="decimal"
                v-model="client_reference"
                placeholder="Reference"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <div
              class="flex justify-content-center field mb-0"
              style="white-space: nowrap"
            >
              <label
                class="label"
                style="margin-right: 40%"
              >
                Progress %
              </label>
              <div style="text-align: right !important">
                <Checkbox
                  id="checkbox"
                  style="margin-top: -3px"
                  v-model="isFinal"
                  :binary="true"
                />
              </div>
              <label style="padding-left: 1.5%; color: #687a8d !important">
                Final Invoice</label>
            </div>

            <div class="p-inputgroup">
              <InputNumber
                :max="100"
                :disabled="isFinal"
                placeholder="Percentage to invoice"
                v-model="progress_percentage"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label class="label">Terms </label>
            <div class="p-inputgroup">
              <Dropdown
                placeholder="Terms"
                :options="termOptions"
                v-model="terms"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label class="label">Issue date </label>
            <Calendar
              placeholder="Issue date"
              v-model="date_issued"
              id="date"
              :showIcon="true"
              dateFormat="yy-mm-dd"
            />
          </div>
          <div class="field col-12 sm:col-6">
            <label class="label">Payment date </label>
            <Calendar
              disabled
              v-model="date_due"
              id="date"
              :showIcon="true"
              dateFormat="yy-mm-dd"
            />
          </div>

          <div class="col-12">
            <div class="field">
              <label
                for="Description"
                class="label"
              >Description </label>
              <Textarea
                rows="5"
                v-model="description"
                placeholder="Description"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-content-between">
          <Button
            type="submit"
            :label="(!this.selectedObject ? 'Create' : 'Update') + ' Invoice'"
            :input-class="['is-primary']"
          />
          <Button
            class="p-button-text p-button-secondary"
            @click="clearSliderContent"
          >
            CANCEL
          </Button>
        </div>
      </form>
    </template>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { state } from "../../../services/data_service";
export default {
  name: "InvoiceSlider",
  components: {},
  data() {
    return {
      submitted: false,
      isLoading: false,
      isFinal: false,
      name: null,
      description: null,
      client_reference: null,
      reference: null,
      date_issued: new Date(),
      date_due: null,
      progress_percentage: null,
      termOptions: ["14 days", "30 days", "60 days"],
      terms: null,
    };
  },
  validations: {},
  beforeDestroy() {
    this.clearSliderContent();
  },
  mounted() {
    if (this.selectedObject) {
      var invoice = JSON.parse(JSON.stringify(this.selectedObject));
      this.name = invoice.name;
      this.description = invoice.description;
      this.client_reference = invoice.client_reference;
      this.progress_percentage = invoice.progress_percentage;

      this.date_due = this.$moment(invoice.date_due).format("YYYY-MM-DD");
      if (invoice.date_issued) {
        this.date_issued = this.$moment(invoice.date_issued).format(
          "YYYY-MM-DD"
        );
      }
    }
    this.terms = this.termOptions[0];
    this.name = this.job?.title;
  },
  methods: {
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;

        var url =
          process.env.VUE_APP_ROOT_API +
          "/v1/finance/invoice" +
          (this.selectedObject ? "/" + this.selectedObject.uid : "");

        if (this.isFinal) {
          url += "?final_invoice=1";
        }

        this.$axios
          .post(
            url,
            JSON.stringify({
              name: this.name,
              description: this.description,
              client_reference: this.client_reference,
              progress_percentage: this.progress_percentage,
              date_issued: this.buildShortDate(new Date(this.date_issued)),
              date_due: this.buildShortDate(this.date_due),
              job: this.$route.params.uid,
            }),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.clearSliderContent();
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
    addDays(date, days) {
      const newDate = new Date(date);
      const numDays = parseInt(days.split(" ")[0]);
      newDate.setDate(newDate.getDate() + numDays);
      return newDate;
    },
  },
  computed: {
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    job() {
      return state.job;
    },
  },
  watch: {
    terms(n) {
      this.date_due = this.addDays(this.date_issued, n);
    },
    date_issued(n) {
      this.date_due = this.addDays(n, this.terms);
    },
    isFinal(n) {
      if (n) {
        this.progress_percentage = 100;
      }
    },
  },
};
</script>